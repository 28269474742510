import React, { useState ,useEffect} from "react";
 
 import {
    Link,
    useNavigate
} from "react-router-dom";
 import ReactQuill from 'react-quill';

  import {HeadBg,MidBg,FootBg,NewMidBg,FstLogo,SiteLogoDark} from '../ImagesIcons.js';


 

// import Header from "../includes/Header";
// import Sidebar from "../includes/Sidebar";
// import Navbar from "../includes/Navbar";
// import Pagetitle from "../includes/Pagetitle";


// import Footer from "../includes/Footer";

import {SetNotificationAfterName,Notifyalert,frontUrl , Url,Whybg,Banner,Vision,Visionfirst,Visionsecond,Visionthird,Visionfour, Servicefirst,Servicesecond,Servicethird,Servicefour,Servicefive,Servicesix,Whyfirst,Whysecond,Whythird,Whyfour,Whyfive,Whysix,  SiteLogo,redirectfrontPage,Redirectdashboard,Redirectlogin ,containerStart , containerEnd , AkuserId,AKcountsupdated} from '../Frontapi.js';




   import Signup from "../auth/Register"


   import Dashboard from "../pages/Dashboard"


 import Footer from "../includes/Footer";


 
const Homepage = () => {
  const navigate = useNavigate();

 
 
    

 
 
 
const [contactname,setcontactName]=useState('');

const [contactemail,setcontactEmail]=useState('');
const [message,setMessage]=useState('');



const [offlemail,setOfflemail]=useState('');
const [offlphone,setOfflphone]=useState('');
const [offlmobile,setOfflmobile]=useState('');

const [offladdress,setOffladdress]=useState('');




 const Getsettings = async (e) => {
  
  try{


     var postdata={getdata:1};

     const response = await fetch(Url+"get-front-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

            

            var settingsdata=data.data;
             setOfflemail(settingsdata.email );
      setOfflphone(settingsdata.phone );
      setOfflmobile(settingsdata.mobile );


            setOffladdress(settingsdata.address+ ', '+settingsdata.city );

 

            
         
          } else{
              //  ;
          }

           
       })



  } catch (error) {

    console.log(error)
      
  }

 } 





  const SubmitContactus = async (e) => {
    e.preventDefault();
 
     try {



    

     var postdata={contactemail:contactemail, contactmessage:message , contactname : contactname };

       /***Check Required***/
            var required_fields= document.getElementsByClassName('MustContactEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                        el.classList.add('mb-25');
                        if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                        el.classList.remove('mb-25');
                        error=1;

                        

                        SetNotificationAfterName(el.name, inputname + ' required')
             }
             });
             /***Check Required***/

            if(error === 1){
                    return false
            }


      const response = await fetch(Url+"create-inquiry", {
        method: "POST",
          headers: {
          "Content-Type": "application/json" 
        } ,
        body:JSON.stringify({postdata})  
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
                                         Notifyalert('success', data.message)

        document.getElementById("ContactForm").reset();
        setMessage('')
          } else{
              //  ;
          }

           
       })
 
    } catch (error) {
      console.log(error)
     }
  }; 
  
   

  
       useEffect(() => {

   Getsettings();

 
   
 
  
  }, []);
   

  return (

     <div id="app">
   <div className=" full-section">
       <div className=" full-section  bg-white">

          <div className="top-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 d-flex justify-content-md-center justify-content-lg-start">
                            <div>
                                <ul className="d-md-flex text-center">
                                    <li className="top-left"><a href={"mailto:"+offlemail} ><span><i
                                                    className="fa-regular fa-envelope top-icon me-1"></i></span>{offlemail}</a>
                                    </li>
                                    <li className="top-left"><a href= {"tel:"+offlphone}><span><i
                                                    className="fa-solid fa-phone  top-icon me-1"></i></span>{offlphone}</a>
                                    </li>
                                    <li className="top-left"><a href={"tel:"+offlmobile}><span><i
                                                    className="fa-brands fa-whatsapp  top-icon me-1"></i></span>{offlmobile}</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        {/*<div className="col-lg-4 col-md-12 d-flex justify-content-center">
                            <div><label className="text-white">Follow us:</label></div>
                            <div>
                                <i className="fa-brands fa-facebook-f  top-icon"></i>
                                <i className="fa-brands fa-instagram top-icon"></i>
                                <i className="fa-brands fa-twitter top-icon"></i>
                                <i className="fa-brands fa-linkedin-in top-icon"></i>
                                <i className="fa-brands fa-youtube top-icon"></i>
                                 
                            </div>
                        </div>*/ }

                    </div>
                </div>
            </div>




                <div className="main-header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg height-auto padding-0">

                        <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                            <a className="navbar-brand ms-2" href="#">
                                <img src={SiteLogoDark} className="mx-wd-100 logo-img-wd"  />
                            </a>
                        </div>


                        <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                            <span><i className="fa-solid fa-bars toggle-icon"></i></span>
                        </button>


                        <div className="collapse navbar-collapse d-none d-lg-flex " id="navbarNav">
                            <div className="col-8 main-link">
                                <ul className="navbar-nav ">
                                    <li className="nav-item">
                                        <a className="nav-link active" href="#">Home <span
                                                className="sr-only">(current)</span></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Services</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">About Us</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Contact Us</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="ms-lg-2 ms-md-0"><Link className="amigobutton"  to={frontUrl+'account'} >Login / Register</Link></div>

                        </div>





                        <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel">
                            <div className="offcanvas-header">
                                <div className="col-3">
                                    <img className="navbar-brand mx-wd-100 logo-img-wd" src={SiteLogoDark}  />
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                                    aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body ">

                                <div className="navbar-nav col-6 main-link">
                                    <li><a className="nav-item nav-link active" href="#">Home <span
                                                className="sr-only">(current)</span></a></li>
                                    <li><a className="nav-item nav-link" href="#">Services</a></li>
                                    <li><a className="nav-item nav-link" href="#">About Us</a></li>
                                    <li><a className="nav-item nav-link" href="#">Contact Us</a></li>
                                </div>


                                <div className="navbar-nav col-lg-3 col-md-12">
                                    <Link className="amigobutton"  to={frontUrl+'account'}>Login / Register</Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>





               <div className="banner-sec mb-5">
                <div className="home-banner">
                    <img src={Banner} />
                </div>
            </div>

            <div className="abt-sec  mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12 abt-lft d-flex justify-content-lg-end justify-content-center">
                            <img src={Vision} className="mx-wd-100" />
                        </div>
                        <div className="abt-right col-lg-6 col-12 pt-2">
                            <h5 className="amigo-text">About Us</h5>
                            <h1>Empowering Kids in the Digital Age with Alfa Kidz</h1>
                            <p>In today's world, mobile phones are an integral part of our children's lives. Rather than
                                restricting their usage, let’s guide them towards positive and enriching content! Alfa
                                Kidz
                                is a vibrant platform where kids can connect with like-minded peers, develop new skills,
                                and
                                unleash their creativity.</p>
                            <p>We invite teachers and coaches to share their expertise on Alfa Kidz, helping young minds
                                achieve new goals and explore exciting skills. Join us in creating a safe, secure and
                                innovative Kids Online Community in Mumbai.</p>

                            <div className="d-flex justify-content-center justify-content-lg-start mb-5"><button
                                    className="amigobutton">About More</button></div>
                        </div>
                    </div>
                </div>
            </div>


             <div className="whyalfa-sec"  style={{ backgroundImage: `url(${Whybg})` }} >
                <div className="container py-5">
                    <div className="text-center text-white">
                        <h5 className="text-white">Why Choose Us</h5>
                        <h2 className="text-white">Why Join Alfa Kidz?</h2>
                        <p>Join Alfa Kidz for a fun, safe, and interactive learning journey! Enhance your child’s skills
                            and
                            creativity with engaging games, videos, and activities tailored for young minds.</p>
                    </div>
                    <div className="row py-2">
                        <div className="col-lg-4 col-md-6 col-12 p-2">
                            <div className="card p-2 ">
                                <div>
                                    <img src={Whyfirst} />
                                </div>
                                <div>
                                    <div>
                                        <h4>Kids Learning Platform</h4>
                                    </div>
                                    <div>
                                        <p>Alfa Kidz, the Best Kids Learning Platform in Mumbai, offers an engaging,
                                            hands-on learning experience that fosters creativity, critical thinking, and
                                            a
                                            love for learning in a fun and safe environment.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 p-2">
                            <div className="card  p-2 ">
                                <div>
                                    <img src={Whysecond} />
                                </div>
                                <div>
                                    <div>
                                        <h4>Register for Kids Talent Showcase</h4>
                                    </div>
                                    <div>
                                        <p>An exciting opportunity to <span><a href="https://www.alfakidz.com/student-register">Register for Kids Talent
                                                    Showcase in Mumbai</a></span>, where
                                            kids can showcase their skills, gain confidence, and connect with peers in a
                                            celebratory environment.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 p-2">
                            <div className="card p-2">
                                <div>
                                    <img src={Whythird} />
                                </div>
                                <div>
                                    <div>
                                        <h4>Sign Up for AI and Robotics Learning</h4>
                                    </div>
                                    <div>
                                        <p>Prepare your child for the future with interactive AI and Robotics courses,
                                            where they’ll learn problem-solving, coding, and innovation in a fun way.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 p-2">
                            <div className="card p-2 ">
                                <div>
                                    <img src={Whyfour} />
                                </div>
                                <div>
                                    <div>
                                        <h4>Online Skill Development</h4>
                                    </div>
                                    <div>
                                        <p>Convenient online courses tailored for your child's growth, covering subjects
                                            like leadership, communication, and creative arts.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6  p-2">
                            <div className="card p-2 ">
                                <div>
                                    <img src={Whyfive} />
                                </div>
                                <div>
                                    <div>
                                        <h4>Motivational Speakers</h4>
                                    </div>
                                    <div>
                                        <p>Empower your child with motivational workshops and speaker sessions that
                                            foster a growth mindset, resilience, and leadership skills.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6  p-2">
                            <div className="card p-2 ">
                                <div>
                                    <img src={Whysix} />
                                </div>
                                <div>
                                    <div>
                                        <h4>Expert Educators</h4>
                                    </div>
                                    <div>
                                        <p>Our team of expert educators and mentors is dedicated to providing the best
                                            learning experience, ensuring every child receives attention and guidance. 
                                            <span><a href="https://www.alfakidz.com/teacher-register">Register Teacher and Counselor</a></span> to be a part of this enriching educational
                                            journey.
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

             <div className="service-sec">
                <div className="container py-5">
                    <div className="text-center ">
                        <h5 className="amigo-text">Our Services </h5>
                        <h3>What does our platform will provide? </h3>
                        <p className="mb-5">Alfa Kidz, the best kids learning platform in Mumbai, offers a range of exciting
                            opportunities
                            for your child’s growth and development. Our platform provides</p>
                    </div>
                    <div className="row py-2">
                        <div className="col-lg-4 col-md-6 col-12 p-2 mb-5">
                            <div className="card p-2 shadow-card">
                                <div className="d-flex justify-content-center">
                                    <div className="square-box">
                                        <img src={Servicefirst} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="text-center">
                                        <h4>Kids Talent Showcase</h4>
                                    </div>
                                    <div className="text-center">
                                        <p>A gallery where kids can submit and display their projects, from artwork and
                                            music to creative writing and more!</p>
                                    </div>
                                    <div className="text-center read-link">
                                         
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 p-2 mb-5">
                            <div className="card p-2 shadow-card">
                                <div className="d-flex justify-content-center">
                                    <div className="square-box">
                                        <img src={Servicesecond}  />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="text-center">
                                        <h4>Explore Technology</h4>
                                    </div>
                                    <div className="text-center">
                                        <p>With our Tech Explorer blog, kids can discover new technologies and
                                            innovations,
                                            explained in a kid-friendly way.</p>
                                    </div>
                                    <div className="text-center read-link">
                                         
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 p-2 mb-5">
                            <div className="card p-2 shadow-card">
                                <div className="d-flex justify-content-center">
                                    <div className="square-box">
                                        <img src={Servicethird} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="text-center">
                                        <h4>Meet Motivational Speakers</h4>
                                    </div>
                                    <div className="text-center">
                                        <p>Our Inspiration Section is dedicated to empowering young minds with
                                            motivational
                                            stories, quotes and videos.</p>
                                    </div>
                                    <div className="text-center read-link">
                                         
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 p-2 mb-lg-0 mb-md-5">
                            <div className="card p-2 shadow-card">
                                <div className="d-flex justify-content-center">
                                    <div className="square-box">
                                        <img src={Servicefour} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="text-center">
                                        <h4>Online Skill Development</h4>
                                    </div>
                                    <div className="text-center">
                                        <p>lfa Kidz offers flexible online skill development programs that help kids
                                            build
                                            essential skills at their own pace.</p>
                                    </div>
                                    <div className="text-center read-link">
                                         
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 p-2">
                            <div className="card p-2 shadow-card">
                                <div className="d-flex justify-content-center">
                                    <div className="square-box">
                                        <img src={Servicefive} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="text-center">
                                        <h4>Online Community for Kids</h4>
                                    </div>
                                    <div className="text-center">
                                        <p>Our Kids' Community, a safe and supportive space where children can freely
                                            discuss their projects, ask questions.</p>
                                    </div>
                                    <div className="text-center read-link">
                                         
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 p-2">
                            <div className="card p-2 shadow-card">
                                <div className="d-flex justify-content-center">
                                    <div className="square-box">
                                        <img src={Servicesix} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="text-center">
                                        <h4>Interactive Learning Tools</h4>
                                    </div>
                                    <div className="text-center">
                                        <p>Alfa Kidz provides engaging games, quizzes, and simulations to enhance
                                            learning,
                                             foster curiosity and problem-solving. </p>
                                    </div>
                                    <div className="text-center read-link">
                                         
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


              <div className="vision-sec">
                <div className="container">
                    <div className="text-center ">
                        <h5 className="amigo-text">Our Vision</h5>
                        <h3>Empowering the Next Generation of Learners</h3>
                        <p>At Alfa Kidz, we aim to inspire and empower children to reach their full potential through a
                            fun
                            and innovative learning environment, fostering creativity, critical thinking and
                            problem-solving
                            skills for a bright future.</p>
                    </div>
                    <div className="row p-2">
                        <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
                            <img src={Visionfirst} alt="vision" />
                        </div>
                        <div className="col-lg-6 col-md-12 p-3">
                            <div>
                                <h4>Our platform promotes</h4>
                            </div>
                            <div className="vision-list">
                                <ul>
                                    <li>Educational content promoting Science, History, Geography, Maths, Language, and
                                        other
                                        essential subjects.</li>
                                    <li>Elegant dance formats, such as Classical or Western.</li>
                                    <li>Mono acts, School Dramas, and Storytelling.</li>
                                    <li>Sports techniques and motivational speakers to foster growth.</li>
                                    <li>Content that improves skills, encourages kids to adopt new skills, and learns
                                        new
                                        cultures and traditions.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row p-2">
                        <div
                            className="col-lg-6 col-md-12  d-flex justify-content-center justify-content-lg-start order-1 order-lg-2">
                            <img src={Visionsecond} alt="vision" />
                        </div>

                        <div className="col-lg-6 col-md-12 pt-5 order-2 order-lg-1">
                            <div>
                                <h4>Our review process guarantees</h4>
                            </div>
                            <div className="vision-list">
                                <ul>
                                    <li> Age-appropriate material</li>
                                    <li>Positive messaging</li>
                                    <li> Respectful and inclusive content</li>
                                    <li> Adherence to copyright laws</li>
                                </ul>
                                <div>
                                    <p>By upholding these standards, we empower kids to grow, learn and thrive in a safe
                                        online space.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row p-2 ">
                        <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
                            <img src={Visionthird} alt="vision" />
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div>
                                <h4>This digital showcase enables</h4>
                            </div>
                            <div>
                                <ul>
                                    <li> Parents to stay informed and engaged with their child's school life</li>
                                    <li>Friends and family to share in students' successes</li>
                                    <li>Prospective parents to make informed decisions about school selection</li>
                                    <li>By highlighting each school's distinct strengths and programs, our platform:
                                    </li>
                                    <li>Fosters community engagement and transparency</li>
                                    <li>Encourages healthy competition among schools</li>
                                    <li>Empowers parents to make informed choices</li>
                                </ul>
                                <div>
                                    <p>Discover, compare, and connect with top schools in your area through our
                                        user-friendly platform.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row p-2">
                        <div
                            className="col-lg-6 col-md-12 d-flex justify-content-lg-start justify-content-md-center  order-1 order-lg-2">
                            <img src={Visionfour} alt="vision" />
                        </div>

                        <div className="col-lg-6 col-md-12 order-2 order-lg-1 pt-5">
                            <div>
                                <h4>Our platform capability</h4>
                            </div>
                            <div>
                                <p>In today's competitive educational landscape, schools strive to stand out. Our
                                    innovative
                                    application provides a unique platform for schools to showcase their extracurricular
                                    activities, student group projects, and achievements.</p>
                            </div>
                        </div>


                    </div>

                </div>
            </div>



            <div className="contactus-sec pt-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-7 col-md-12 contactus-left">
                            <div className="col-10 py-5 left-contact">
                                <div className="ms-3">
                                    <h2>Contact Us</h2>
                                    <div className="privacy-border"></div>
                                </div>
                                <form id="ContactForm">
                                    <div className="d-flex flex-wrap">
                                        <div className="col-12 col-md-6 p-2"><input className="form-control MustContactEnterField" type="text" name="Name"
                                                id="name" placeholder="Name*" onChange={(e) => setcontactName(e.target.value)} value={contactname}  required /></div>
                                   
                                        <div className="col-12 col-md-6 p-2"><input className="form-control MustContactEnterField" type="text" name="Email"
                                                id="name" placeholder="Email*" required onChange={(e) => setcontactEmail(e.target.value)}  value={contactemail} /></div>
 
                                        <div className="col-12 p-2 text-cont">



                             <ReactQuill value={message}  onChange={setMessage} name="message" placeholder="Enter your message*" rows="10" className="form-control textarea-contact-input MustContactEnterField mb-25"  />

                                    


                                                </div>
                                    </div>
                                    <div className="text-center"> <button className="amigobutton" onClick={SubmitContactus}>Submit</button></div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 contactus-right">
                            <div className="p-4 ">
                                <div className="d-flex  py-3 ">
                                    <div className=" d-flex align-items-center justify-content-center">
                                        <div className="contact-icon">
                                            <i className="fa-solid fa-phone icon-contact"></i>
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <h4 className="contact-text text-white">Call</h4> <span
                                            className="contact-txt ">{offlphone}</span>
                                    </div>
                                </div>
                                <div className="d-flex  py-3 ">
                                    <div className=" d-flex align-items-center justify-content-center">
                                        <div className="contact-icon">
                                            <i className="fa-brands fa-whatsapp icon-contact"></i>
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <h4 className="contact-text text-white">WhatsApp</h4> <span
                                            className="contact-txt">{offlmobile}</span>
                                    </div>
                                </div>
                                <div className="d-flex    py-3">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="contact-icon">
                                            <i className="fa-regular fa-envelope  icon-contact"></i>
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <h4 className="contact-text text-white">Email</h4> <span
                                            className="contact-txt">{offlemail}</span>
                                    </div>
                                </div>
                                  {/* <div className="d-flex flex-wrap  py-3">
                                    <div className=" d-flex align-items-center justify-content-center">
                                        <div className="contact-icon">
                                            <i className="fa-solid fa-user-plus icon-contact"></i>
                                        </div>
                                    </div>
                                  <div className="ms-3">
                                        <h4 className="contact-text text-white">Follow Us</h4> <span className="contact-txt">
                                            <div className="d-flex">
                                                <i className="fa-brands fa-facebook-f  contact-txt px-1"></i>
                                                <i className="fa-brands fa-instagram  contact-txt px-1"></i>
                                                <i className="fa-brands fa-twitter contact-txt  px-1"></i>
                                                <i className="fa-brands fa-linkedin-in contact-txt  px-1"></i>
                                                <i className="fa-brands fa-youtube contact-txt  px-1"></i>
                                                 
                                            </div>
                                        </span>
                                    </div>
                                </div>*/}
                                <div className="d-flex    py-3">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="contact-icon">
                                            <i className="fa-solid fa-location-dot icon-contact"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-7  ms-3">
                                        <h4 className="contact-text text-white">Address </h4> <span
                                            className="contact-txt">{offladdress}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





       
 
      </div>
      
   </div>
</div>
 
  );
};
export default Homepage;