import React ,{useEffect,useState} from "react";
 
import {Redirectlogin,Url,SiteFavicon} from '../Frontapi.js';
 import MetaTags from 'react-meta-tags';

   
 import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

  import  ('./style-f.css');


const Header = ({pageInfo,title,meta_keywords,meta_description}) => {
  

   const [companyname, setCompanyname] = useState("");
   const [metatitle, setMetatitle] = useState("");
   const [metakeywords, setMetakeywords] = useState("");
   const [metadescription, setMetadescription] = useState("");



 const Getsettings = async (e) => {
  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-front-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
             setCompanyname(settingsdata.companyname );
            

            
         
          } else{
              //  ;
          }

           
       })



  } catch (error) {
      
  }

 } 

const setMetadata =async() => {

if(typeof pageInfo == 'undefined' && typeof title == 'undefined'){
 
}else if(typeof title != 'undefined'){
 

 
     setMetatitle(title)
    


}else{
     
 
   if(pageInfo == 'Login'){
       setMetatitle(companyname +' - '+ 'Login')
       setMetakeywords('Login')
       setMetadescription('Login')
   }
     else if(pageInfo == 'Register'){
       setMetatitle(companyname +' - '+ 'Register')
       setMetakeywords('Register')
       setMetadescription('Register')
   }else      if(pageInfo == 'Dashboard'){
       setMetatitle(companyname +' - '+ 'Dashboard')
       
       setMetakeywords('Dashboard')
       setMetadescription('Dashboard')
     } 
else      if(pageInfo == 'Home'){
       setMetatitle('Empower Kids with Alfa Kidz - Explore Talent, Learning & Skill Development in Mumbai')
         setMetakeywords('Kids learning platform in Mumbai, AI and Robotics for kids, Kids talent showcase Mumbai, Online skill development for children, Motivational speakers for kids, Children�s online community, Tech skills for kids, School project showcase Mumbai, Safe online space for kids, Mumbai kids talent program, Growth and development for children, AI courses for kids Mumbai, Extracurricular activities platform for kids')
       setMetadescription('Join Alfa Kidz, a secure online platform for kids in Mumbai to explore AI, Robotics, talent showcases, motivational content, and skill development programs')
   

   } 


   else {
        setMetatitle(companyname +' - '+ pageInfo)
       setMetakeywords(pageInfo)
       setMetadescription(pageInfo)
   }  


   
}


 } 

 useEffect(() => {
 Getsettings();

 setMetadata();
  }, []); 

   

  return (

<div>
   <MetaTags>
     <title>{metatitle}</title>

      

      <link rel="shortcut icon" href={SiteFavicon} />

  
      <meta id="meta-description" name="description" content={meta_description} />
      <meta id="meta-keywords" name="keywords" content={meta_keywords} />

 

      <meta id="og-title" property="og:title" content={companyname +' - '+ metatitle} />
      <meta id="og-image" property="og:image" content="" />
   </MetaTags>
    <NotificationContainer/>



 


</div>
 
 

  
  );
};
export default Header;